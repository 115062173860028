<template lang="">
  <div>
    <SubHeader :data="data" class="sub-header-responsive" />
    <div class="container">
      <el-row>
        <el-col>
          <ol v-for="(item, index) in helpCenterFilter" :key="'compo'+index"  class="link-mos-app-support">
            <li><router-link to="/help-center">MosApp {{ $t(`HelpCenter['support']`) }}</router-link></li>
            <li @click="goToList(item.title)"><router-link to="">{{ $t(`HelpCenter['${item.title}']`) }}</router-link></li>
            <li>{{ $t(`HelpCenter['${categoryName}']`) }}</li>
          </ol>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :xs="24" :sm="24" :md="7" class="help-center-list">
          <div class="category" v-for="(item, index) in helpCenterFilter" :key="'analyse'+index">
            <h1 style="font-size:28px">{{ $t(`HelpCenter['${item.title}']`) }}</h1>
            <ul v-for="(list, index) in item.childs" :key="index">
              <li :class="compName == list.keyValue ? 'list-category active': 'list-category'" @click="goToDetail(list.title, list.keyValue, list.page)">{{ $t(`HelpCenter['${list.title}']`) }}</li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="17" class="help-center-detail">
          <div class="dashboard">
            <div class="list-child-detail">
              <KeepAlive :max="2">
                <component :is="compName"></component>
              </KeepAlive>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import SubHeader from '../components/SubHeader.vue'
/* Get Start */
import WhatIsMosApp from './helpCenterDetails/GetStart/WhatIsMosApp.vue'
import MosAppAvaliableMulty from './helpCenterDetails/GetStart/MosAppAvaliableMulty.vue'
import MosAppSecure from './helpCenterDetails/GetStart/MosAppSecure.vue'
import CanIUseMosApptoMakeCall from './helpCenterDetails/GetStart/CanIUseMosApptoMakeCall.vue'
import CanIcreateGroupChars from './helpCenterDetails/GetStart/CanIcreateGroupChars.vue'
import CanIshareFilesAndmedia from './helpCenterDetails/GetStart/CanIshareFilesAndmedia.vue'
import CanICustomizemyChatExperience from './helpCenterDetails/GetStart/CanICustomizemyChatExperience.vue'
import AremyMessagesBackedUp from './helpCenterDetails/GetStart/AremyMessagesBackedUp.vue'
import HowCanIreportanyIssuesOrProvideFeedback from './helpCenterDetails/GetStart/HowCanIreportanyIssuesOrProvideFeedback.vue'
import HowToTranslateaChatMessage from './helpCenterDetails/GetStart/HowToTranslateaChatMessage.vue'
/* MosApp Instant Messaging */
import WhatisMosAppInstantMessaging from './helpCenterDetails/MosAppIM/WhatisMosAppInstantMessaging.vue'
import HowMosAppInstantMessagingWork from './helpCenterDetails/MosAppIM/HowMosAppInstantMessagingWork.vue'
import AreMosappInstantMessagesSecurePrivate from './helpCenterDetails/MosAppIM/AreMosappInstantMessagesSecurePrivate.vue'
import CanMosappInstantMessagingMultipleDevices from './helpCenterDetails/MosAppIM/CanMosappInstantMessagingMultipleDevices.vue'
import InternetConnectionMosappIM from './helpCenterDetails/MosAppIM/InternetConnectionMosappIM.vue'
/* Voice Call */
import WhatMosAppVoiceCallFeature from './helpCenterDetails/VoiceCall/WhatMosAppVoiceCallFeature.vue'
import HowToMakeVoiceCallonMosApp from './helpCenterDetails/VoiceCall/HowToMakeVoiceCallonMosApp.vue'
import CanMakeVoiceMosApp from './helpCenterDetails/VoiceCall/CanMakeVoiceMosApp.vue'
import VoiceCallsonMosApp from './helpCenterDetails/VoiceCall/VoiceCallsonMosApp.vue'
import CanMakeGroupVoiceCall from './helpCenterDetails/VoiceCall/CanMakeGroupVoiceCall.vue'
import MosAppVoiceCallSecurePrivate from './helpCenterDetails/VoiceCall/MosAppVoiceCallSecurePrivate.vue'
/*  Video Call */
import MosappVideoCall from './helpCenterDetails/VideoCall/MosappVideoCall.vue'
import MakeVideoCall from './helpCenterDetails/VideoCall/MakeVideoCall.vue'
import MakeGroupVideoCall from './helpCenterDetails/VideoCall/MakeGroupVideoCall.vue'

/* Accounts */
import CreateAccountMosapp from './helpCenterDetails/Accounts/CreateAccountMosapp.vue'
import CreateStrongPasswordMosapp from './helpCenterDetails/Accounts/CreateStrongPasswordMosapp.vue'
import ChangeMyPasswordMosapp from './helpCenterDetails/Accounts/ChangeMyPasswordMosapp.vue'
import ForgotMyMosappPassword from './helpCenterDetails/Accounts/ForgotMyMosappPassword.vue'
import EnsureSecurityMyMosappPassword from './helpCenterDetails/Accounts/EnsureSecurityMyMosappPassword.vue'
import MosappPasswordStoredSecurely from './helpCenterDetails/Accounts/MosappPasswordStoredSecurely.vue'
import ViewMosappPassword from './helpCenterDetails/Accounts/ViewMosappPassword.vue'
import SafeSaveMosappPasswordDevice from './helpCenterDetails/Accounts/SafeSaveMosappPasswordDevice.vue'
import OftenChangeMosappPassword from './helpCenterDetails/Accounts/OftenChangeMosappPassword.vue'
import ShouldIAccessedMosappAccount from './helpCenterDetails/Accounts/ShouldIAccessedMosappAccount.vue'
/* Channel */
import ChannelFunctionMosApp from './helpCenterDetails/Channel/ChannelFunctionMosApp.vue'
import CreatePrivateChannelMosApp from './helpCenterDetails/Channel/CreatePrivateChannelMosApp.vue'
// import CreatingPrivateChannelMosApp1 from './helpCenterDetails/Channel/CreatingPrivateChannelMosApp1.vue'
import WhatIsDifferenceBetweenPublicAndPrivateChannel from './helpCenterDetails/Channel/WhatIsDifferenceBetweenPublicAndPrivateChannel.vue'
import AddSubscribersInChannel from './helpCenterDetails/Channel/AddSubscribersInChannel.vue'
/* Privacy */
import MosAppHandlemyPersonalDataAndPrivacy from './helpCenterDetails/Privacy/MosAppHandlemyPersonalDataAndPrivacy.vue'
import WhatPersonalInformationDuringRegistration from './helpCenterDetails/Privacy/WhatPersonalInformationDuringRegistration.vue'
import DoesMosAppSharePersonalDataWithThirdParties from './helpCenterDetails/Privacy/DoesMosAppSharePersonalDataWithThirdParties.vue'
import HowIsmyDataUsedWithinMosApp from './helpCenterDetails/Privacy/HowIsmyDataUsedWithinMosApp.vue'
import CanIControlWhoCanSeemyMobileNumber from './helpCenterDetails/Privacy/CanIControlWhoCanSeemyMobileNumber.vue'
import IsMyCallhistorystoredonMosApp from './helpCenterDetails/Privacy/IsMyCallhistorystoredonMosApp.vue'
import HowLongdoesMosAppRetainmyCallRecordingsAndOtherData from './helpCenterDetails/Privacy/HowLongdoesMosAppRetainmyCallRecordingsAndOtherData.vue'
import IsMyLocationInformationSharedDuringVideoCalls from './helpCenterDetails/Privacy/IsMyLocationInformationSharedDuringVideoCalls.vue'
import HowDoesMosAppProtectmyConversationsFromEavesdropping from './helpCenterDetails/Privacy/HowDoesMosAppProtectmyConversationsFromEavesdropping.vue'
import CanIDeleteMyMosAppAccountAndData from './helpCenterDetails/Privacy/CanIDeleteMyMosAppAccountAndData.vue'
import HowDoesMosAppComplyWithDataProtectionLawsAndRegulations from './helpCenterDetails/Privacy/HowDoesMosAppComplyWithDataProtectionLawsAndRegulations.vue'
import CanIRequestaCopyofmyDataStoredMosApp from './helpCenterDetails/Privacy/CanIRequestaCopyofmyDataStoredMosApp.vue'
import HowCanIReportPrivacyConcernOrDataBreachToMosApp from './helpCenterDetails/Privacy/HowCanIReportPrivacyConcernOrDataBreachToMosApp.vue'
/* Security */
import IsMosAppSecureForMakingVideoCalls from './helpCenterDetails/Security/IsMosAppSecureForMakingVideoCalls.vue'
import CanIReportSuspiciousActivityOrSecurityConcernstoMosApp from './helpCenterDetails/Security/CanIReportSuspiciousActivityOrSecurityConcernstoMosApp.vue'
import HowDoesMosAppHandleSecurityVulnerabilitiesAndUpdates from './helpCenterDetails/Security/HowDoesMosAppHandleSecurityVulnerabilitiesAndUpdates.vue'
import HowDoesMosAppProtectMyPersonalInformation from './helpCenterDetails/Security/HowDoesMosAppProtectMyPersonalInformation.vue'
import HowDoyouHandleDataPrivacyRegulationsAndCompliance from './helpCenterDetails/Security/HowDoyouHandleDataPrivacyRegulationsAndCompliance.vue'
import WhatMeasuresAreInPlaceToPreventUnauthorizedAccesstomyAccount from './helpCenterDetails/Security/WhatMeasuresAreInPlaceToPreventUnauthorizedAccesstomyAccount.vue'
import WhatShouldMosAppAccountHasBeenCompromised from './helpCenterDetails/Security/WhatShouldMosAppAccountHasBeenCompromised.vue'
/* Encrypted Group */
import WhatEncryptedGroupIn from './helpCenterDetails/EncryptedGroup/WhatEncryptedGroupIn.vue'
import HowToCreateAnEncryptedGroupIn from './helpCenterDetails/EncryptedGroup/HowToCreateAnEncryptedGroupIn.vue'

export default {
  name: 'HelpCenterDetail',
  props: {

  },
  components: {
    SubHeader,
    WhatIsMosApp,
    MosAppAvaliableMulty,
    MosAppSecure,
    CanIUseMosApptoMakeCall,
    CanIcreateGroupChars,
    CanIshareFilesAndmedia,
    CanICustomizemyChatExperience,
    AremyMessagesBackedUp,
    HowCanIreportanyIssuesOrProvideFeedback,
    HowToTranslateaChatMessage,
    WhatisMosAppInstantMessaging,
    HowMosAppInstantMessagingWork,
    AreMosappInstantMessagesSecurePrivate,
    CanMosappInstantMessagingMultipleDevices,
    InternetConnectionMosappIM,
    WhatMosAppVoiceCallFeature,
    HowToMakeVoiceCallonMosApp,
    CanMakeVoiceMosApp,
    VoiceCallsonMosApp,
    CanMakeGroupVoiceCall,
    MosAppVoiceCallSecurePrivate,
    MosappVideoCall,
    MakeVideoCall,
    MakeGroupVideoCall,
    CreateAccountMosapp,
    CreateStrongPasswordMosapp,
    ChangeMyPasswordMosapp,
    ForgotMyMosappPassword,
    EnsureSecurityMyMosappPassword,
    MosappPasswordStoredSecurely,
    ViewMosappPassword,
    SafeSaveMosappPasswordDevice,
    OftenChangeMosappPassword,
    ShouldIAccessedMosappAccount,
    ChannelFunctionMosApp,
    CreatePrivateChannelMosApp,
    // CreatingPrivateChannelMosApp1,
    WhatIsDifferenceBetweenPublicAndPrivateChannel,
    AddSubscribersInChannel,
    MosAppHandlemyPersonalDataAndPrivacy,
    WhatPersonalInformationDuringRegistration,
    DoesMosAppSharePersonalDataWithThirdParties,
    HowIsmyDataUsedWithinMosApp,
    CanIControlWhoCanSeemyMobileNumber,
    IsMyCallhistorystoredonMosApp,
    HowLongdoesMosAppRetainmyCallRecordingsAndOtherData,
    IsMyLocationInformationSharedDuringVideoCalls,
    HowDoesMosAppProtectmyConversationsFromEavesdropping,
    CanIDeleteMyMosAppAccountAndData,
    HowDoesMosAppComplyWithDataProtectionLawsAndRegulations,
    CanIRequestaCopyofmyDataStoredMosApp,
    HowCanIReportPrivacyConcernOrDataBreachToMosApp,
    IsMosAppSecureForMakingVideoCalls,
    CanIReportSuspiciousActivityOrSecurityConcernstoMosApp,
    HowDoesMosAppHandleSecurityVulnerabilitiesAndUpdates,
    HowDoesMosAppProtectMyPersonalInformation,
    HowDoyouHandleDataPrivacyRegulationsAndCompliance,
    WhatMeasuresAreInPlaceToPreventUnauthorizedAccesstomyAccount,
    WhatShouldMosAppAccountHasBeenCompromised,
    WhatEncryptedGroupIn,
    HowToCreateAnEncryptedGroupIn
  },
  data() {
    return {
      data: {
        label_name: 'helpCenter',
        content_desc: 'helpCenterContent',
        img: require('@/assets/image/sub-header/help-center.png')
      },
      isCategoryActive: 0,
      pageSlug: '',
      compName: '',
      categoryName: '',
      helpCenterFilter: [],
      getHelpCenter: [
        {
          title: 'GetStart',
          childs: [
            {
              id: 1,
              title: 'category1',
              page: 'what-is-mosapp',
              keyValue: 'WhatIsMosApp'
            },
            {
              id: 2,
              title: 'category2',
              page: 'mosapp-available-platform',
              keyValue: 'MosAppAvaliableMulty'
            },
            {
              id: 3,
              title: 'category3',
              page: 'mosapp-secure',
              keyValue: 'MosAppSecure'
            },
            {
              id: 4,
              title: 'category4',
              page: 'can-use-mosapp-make-call',
              keyValue: 'CanIUseMosApptoMakeCall'
            },
            {
              id: 5,
              title: 'category5',
              page: 'can-i-create-group-chat',
              keyValue: 'CanIcreateGroupChars'
            },
            {
              id: 6,
              title: 'category6',
              page: 'can-i-share-files-media',
              keyValue: 'CanIshareFilesAndmedia'
            },
            {
              id: 7,
              title: 'category7',
              page: 'can-i-customize-chat-experience',
              keyValue: 'CanICustomizemyChatExperience'
            },
            {
              id: 8,
              title: 'category8',
              page: 'are-my-message-backedup',
              keyValue: 'AremyMessagesBackedUp'
            },
            {
              id: 9,
              title: 'category9',
              page: 'how-can-i-report-issues',
              keyValue: 'HowCanIreportanyIssuesOrProvideFeedback'
            },
            {
              id: 10,
              title: 'category10',
              page: 'how-to-translate-chat-message',
              keyValue: 'HowToTranslateaChatMessage'
            }
          ]
        },
        {
          title: 'MosAppInstandMessaging',
          childs: [
            {
              id: 11,
              title: 'category11',
              page: 'what-is-instant-message',
              keyValue: 'WhatisMosAppInstantMessaging'
            },
            {
              id: 12,
              title: 'category12',
              page: 'how-mosapp-message-work',
              keyValue: 'HowMosAppInstantMessagingWork'
            },
            {
              id: 13,
              title: 'category13',
              page: 'are-mosapp-message-secure',
              keyValue: 'AreMosappInstantMessagesSecurePrivate'
            },
            {
              id: 14,
              title: 'category14',
              page: 'can-mosapp-message-multiple-device',
              keyValue: 'CanMosappInstantMessagingMultipleDevices'
            },
            {
              id: 15,
              title: 'category15',
              page: 'internet-connection-mosappIM',
              keyValue: 'InternetConnectionMosappIM'
            }
          ]
        },
        {
          title: 'VoiceCall',
          childs: [
            {
              id: 16,
              title: 'category16',
              page: 'What-is-MosApp-Voice-Call-Feature',
              keyValue: 'WhatMosAppVoiceCallFeature'
            },
            {
              id: 17,
              title: 'category17',
              page: 'How-To-Make-Voice-Call-on-MosApp',
              keyValue: 'HowToMakeVoiceCallonMosApp'
            },
            {
              id: 18,
              title: 'category18',
              page: 'Can-Make-Voice-MosApp',
              keyValue: 'CanMakeVoiceMosApp'
            },
            {
              id: 19,
              title: 'category19',
              page: 'Voice-Calls-on-MosApp',
              keyValue: 'VoiceCallsonMosApp'
            },
            {
              id: 20,
              title: 'category20',
              page: 'Can-Make-Group-Voice-Call',
              keyValue: 'CanMakeGroupVoiceCall'
            },
            {
              id: 21,
              title: 'category21',
              page: 'MosApp-Voice-Call-Secure-Private',
              keyValue: 'MosAppVoiceCallSecurePrivate'
            }
          ]
        },
        {
          title: 'VideoCall',
          childs: [
            {
              id: 22,
              title: 'category22',
              page: 'Mosapp-Video-Call',
              keyValue: 'MosappVideoCall'
            },
            {
              id: 23,
              title: 'category23',
              page: 'Make-Video-Call',
              keyValue: 'MakeVideoCall'
            },
            {
              id: 24,
              title: 'category24',
              page: 'Make-Group-Video-Call',
              keyValue: 'MakeGroupVideoCall'
            }
          ]
        },
        {
          title: 'Accounts',
          childs: [
            {
              id: 25,
              title: 'category25',
              page: 'Create-Account-Mosapp',
              keyValue: 'CreateAccountMosapp'
            },
            {
              id: 26,
              title: 'category26',
              page: 'Create-Strong-Password-Mosapp',
              keyValue: 'CreateStrongPasswordMosapp'
            },
            {
              id: 27,
              title: 'category27',
              page: 'Change-My-Password-Mosapp',
              keyValue: 'ChangeMyPasswordMosapp'
            },
            {
              id: 28,
              title: 'category28',
              page: 'Forgot-My-Mosapp-Password',
              keyValue: 'ForgotMyMosappPassword'
            },
            {
              id: 29,
              title: 'category29',
              page: 'Ensure-Security-My-Mosapp-Password',
              keyValue: 'EnsureSecurityMyMosappPassword'
            },
            {
              id: 30,
              title: 'category30',
              page: 'Mosapp-Password-Stored-Securely',
              keyValue: 'MosappPasswordStoredSecurely'
            },
            {
              id: 31,
              title: 'category31',
              page: 'View-Mosapp-Password',
              keyValue: 'ViewMosappPassword'
            },
            {
              id: 32,
              title: 'category32',
              page: 'Safe-Save-Mosapp-Password-Device',
              keyValue: 'SafeSaveMosappPasswordDevice'
            },
            {
              id: 33,
              title: 'category33',
              page: 'Often-Change-Mosapp-Password',
              keyValue: 'OftenChangeMosappPassword'
            },
            {
              id: 34,
              title: 'category33',
              page: 'Should-I-Accessed-Mosapp-Account',
              keyValue: 'ShouldIAccessedMosappAccount'
            }
          ]
        },
        {
          title: 'Channel',
          childs: [
            {
              id: 41,
              title: 'ChannelCategory1',
              page: 'Channel-Function-MosApp',
              keyValue: 'ChannelFunctionMosApp'
            },
            {
              id: 42,
              title: 'ChannelCategory2',
              page: 'Create-Private-Channel-MosApp',
              keyValue: 'CreatePrivateChannelMosApp'
            },
            {
              id: 43,
              title: 'ChannelCategory3',
              page: 'What-is-difference-between-private-channel',
              keyValue: 'WhatIsDifferenceBetweenPublicAndPrivateChannel'
            },
            {
              id: 44,
              title: 'ChannelCategory4',
              page: 'Add-subscribers-channel',
              keyValue: 'AddSubscribersInChannel'
            }
          ]
        },
        {
          title: 'PrivacySecurity',
          childs: [
            {
              id: 52,
              title: 'PrivacyCategory1',
              page: 'MosApp-Handle-my-Personal-Data-And-Privacy',
              keyValue: 'MosAppHandlemyPersonalDataAndPrivacy'
            },
            {
              id: 53,
              title: 'PrivacyCategory2',
              page: 'What-Personal-Information-During-Registration',
              keyValue: 'WhatPersonalInformationDuringRegistration'
            },
            {
              id: 54,
              title: 'PrivacyCategory3',
              page: 'Does-MosApp-Share-Personal-Data-With-Third-Parties',
              keyValue: 'DoesMosAppSharePersonalDataWithThirdParties'
            },
            {
              id: 55,
              title: 'PrivacyCategory4',
              page: 'How-Is-my-Data-Used-With-in-MosApp',
              keyValue: 'HowIsmyDataUsedWithinMosApp'
            },
            {
              id: 56,
              title: 'PrivacyCategory5',
              page: 'Can-I-Control-Who-Can-See-my-Mobile-Number',
              keyValue: 'CanIControlWhoCanSeemyMobileNumber'
            },
            {
              id: 57,
              title: 'PrivacyCategory6',
              page: 'Is-My-Call-history-stored-on-MosApp',
              keyValue: 'IsMyCallhistorystoredonMosApp'
            },
            {
              id: 58,
              title: 'PrivacyCategory7',
              page: 'How-Long-does-MosApp-Retain-my-Call-Recordings-And-Other-Data',
              keyValue: 'HowLongdoesMosAppRetainmyCallRecordingsAndOtherData'
            },
            {
              id: 59,
              title: 'PrivacyCategory8',
              page: 'Is-My-Location-Information-Shared-During-Video-Calls',
              keyValue: 'IsMyLocationInformationSharedDuringVideoCalls'
            },
            {
              id: 60,
              title: 'PrivacyCategory9',
              page: 'How-Does-MosApp-Protect-my-Conversations-From-Eaves-dropping',
              keyValue: 'HowDoesMosAppProtectmyConversationsFromEavesdropping'
            },
            {
              id: 61,
              title: 'PrivacyCategory10',
              page: 'Can-I-Delete-My-MosApp-Account-And-Data',
              keyValue: 'CanIDeleteMyMosAppAccountAndData'
            },
            {
              id: 62,
              title: 'PrivacyCategory11',
              page: 'How-Does-MosApp-Comply-With-Data-Protection-Laws-And-Regulations',
              keyValue: 'HowDoesMosAppComplyWithDataProtectionLawsAndRegulations'
            },
            {
              id: 63,
              title: 'PrivacyCategory12',
              page: 'Can-I-Request-a-Copy-of-my-Data-Stored-MosApp',
              keyValue: 'CanIRequestaCopyofmyDataStoredMosApp'
            },
            {
              id: 64,
              title: 'PrivacyCategory13',
              page: 'How-Can-I-Report-Privacy-Concern-Or-Data-Breach-To-MosApp',
              keyValue: 'HowCanIReportPrivacyConcernOrDataBreachToMosApp'
            }
          ]
        },
        {
          title: 'EncryptedGroup',
          childs: [
            {
              id: 65,
              title: 'EncryptedGroupCategory1',
              page: 'What-Encrypted-Group-In',
              keyValue: 'WhatEncryptedGroupIn'
            },
            {
              id: 66,
              title: 'EncryptedGroupCategory2',
              page: 'How-To-Create-An-Encrypted-Group-In',
              keyValue: 'HowToCreateAnEncryptedGroupIn'
            }
          ]
        },
        {
          title: 'Security',
          childs: [
            {
              id: 70,
              title: 'SecurityCategory1',
              page: 'Is-MosApp-Secure-For-Making-Video-Calls',
              keyValue: 'IsMosAppSecureForMakingVideoCalls'
            },
            {
              id: 71,
              title: 'SecurityCategory2',
              page: 'How-Does-MosApp-Protect-My-Personal-Information',
              keyValue: 'HowDoesMosAppProtectMyPersonalInformation'
            },
            {
              id: 72,
              title: 'SecurityCategory3',
              page: 'What-Measures-Are-In-Place-To-Prevent-Unauthorized-Access-to-my-Account',
              keyValue: 'WhatMeasuresAreInPlaceToPreventUnauthorizedAccesstomyAccount'
            },
            {
              id: 73,
              title: 'SecurityCategory4',
              page: 'How-Does-MosApp-Handle-Security-Vulner-abilities-And-Updates',
              keyValue: 'HowDoesMosAppHandleSecurityVulnerabilitiesAndUpdates'
            },
            {
              id: 74,
              title: 'SecurityCategory5',
              page: 'Can-I-Report-Suspicious-Activity-Or-Security-Concerns-to-MosApp',
              keyValue: 'CanIReportSuspiciousActivityOrSecurityConcernstoMosApp'
            },
            {
              id: 75,
              title: 'SecurityCategory6',
              page: 'What-Should-MosApp-Account-Has-Been-Compromised',
              keyValue: 'WhatShouldMosAppAccountHasBeenCompromised'
            },
            {
              id: 76,
              title: 'SecurityCategory7',
              page: 'How-Do-you-Handle-Data-Privacy-Regulations-And-Compliance',
              keyValue: 'HowDoyouHandleDataPrivacyRegulationsAndCompliance'
            }
          ]
        }
      ],
      goToList(title) {
        this.$router.push({ name: 'Help center list', params: { title: title } })
      }
    }
  },
  created() {
    if (this.$route.params.title) {
      this.compName = this.$route.params.title
      const filterArr = this.getHelpCenter.filter((item) => item.title === this.$route.params.category)
      this.helpCenterFilter = filterArr
      const catArr = filterArr[0].childs.filter((item) => item.page === this.$route.params.page)
      this.categoryName = catArr[0].title
    } else {
      const filterHelperCenter = this.getHelpCenter.filter(category => {
        return category.childs.filter(p => {
          return p.page === this.$route.params.page
        }).length > 0
      })
      const filterArr = filterHelperCenter[0].childs.filter((item) => item.page === this.$route.params.page)
      this.compName = filterArr[0].keyValue
      this.categoryName = filterArr[0].title
      this.helpCenterFilter = filterHelperCenter
    }
  },
  methods: {
    goToDetail(categoryname, title, page, id) {
      this.$router.push( { name: 'Help center detail', params: { title: title, page: page } }).catch(() => {})
      this.compName = title
      this.isCategoryActive = id
      this.categoryName = categoryname
    },
    filteredQuestions() {
      return this.getHelpCenter.filter((item) => {
        return item.childs.map((val) => val.page === this.$route.params.page)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.help-center-list {
  margin-top: 10px;
  margin-bottom: 50px;
  @media screen and (max-width: 992px) {
    margin-bottom: 0px;
  }
}
.d-none {
  display: none;
}
.help-center-detail {
  margin-top: 10px;
  @media screen and (max-width: 992px) {
    margin-top: 0px;
  }
}
@media screen and (max-width: 992px) {
  .sub-header-responsive {
    display: none;
  }
}
.dashboard h1 {
  font-weight: 500;
  margin-bottom: 15px;
}
.link-mos-app-support {
  margin-top: 20px;
  color: #3370FF;
  display: flex;
  -moz-column-gap: 10px;
  column-gap: 5px;
  flex-wrap: wrap;
  li {
    cursor: pointer;
    margin-bottom: 20px;
    @media screen and (max-width: 992px) {
      margin-bottom: 0px;
    }
  }
  li a {
      color: inherit;
  }
  li a:hover {
      text-decoration: underline;
  }
  li:last-child {
    color: black;
  }
  @media screen and (max-width: 992px) {
    margin-top: 100px;
    font-size: 14px;
  }
}
.link-mos-app-support li + li::before {
    content: ">";
    margin: 0px 5px 0px 5px;
    color: #B4B4B4;
}
.link-mos-app-support li + li::before {
  margin: 0px 5px 0px 5px;
}
.category {
  @media screen and (max-width: 992px) {
    display: none;
  }
  li.list-category {
    margin: 5px 0;
    padding: 10px 10px;
    border-radius: 8px;
    cursor: pointer;
    color: #3370FF;
  }
  li.list-category:hover {
    background-color: #E7EEFF;
  }
  li.list-category.active {
    background-color: #E7EEFF;
  }
}
</style>
