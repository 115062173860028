<template>
  <div class="handle-content">
    <h1>{{ $t('HelpCenter.SecurityCategory1') }}</h1>
    <h3>{{ $t('HelpCenter.SecurityCategoryDesc1') }}</h3>
  </div>
</template>
<script>
export default {
  name: 'IsMosAppSecureForMakingVideoCalls'
}
</script>
<style lang="less" scoped>
  .handle-content {
    margin-bottom: 20px;
    h1 {
      font-size: 30px;
      font-weight: 500;
      @media screen and (max-width: 992px) {
        margin-right: 35px;
        font-size: 24px;
      }
    }
    h3 {
      font-size: 18px;
      padding: 10px 0;
    }
    img {
      margin: 15px 0;
      width: 100%;
    }
  }
</style>
