import { render, staticRenderFns } from "./ChangeMyPasswordMosapp.vue?vue&type=template&id=4030f22c&scoped=true&"
import script from "./ChangeMyPasswordMosapp.vue?vue&type=script&lang=js&"
export * from "./ChangeMyPasswordMosapp.vue?vue&type=script&lang=js&"
import style0 from "./ChangeMyPasswordMosapp.vue?vue&type=style&index=0&id=4030f22c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4030f22c",
  null
  
)

export default component.exports