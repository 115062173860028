<template>
  <div class="handle-content">
    <h1>{{ $t('HelpCenter.ChannelCategory4') }}</h1>
    <h3>{{ $t('HelpCenter.ChannelCategory4Desc1') }}</h3><br/>
    <h3>{{ $t('HelpCenter.ChannelCategory4Desc2') }}</h3>
    <h3>1. {{ $t('HelpCenter.ChannelCategory4Desc3') }}</h3>
    <h3>2. {{ $t('HelpCenter.ChannelCategory4Desc4') }}</h3>
    <h3>3. {{ $t('HelpCenter.ChannelCategory4Desc5') }}</h3>
    <h3>4. {{ $t('HelpCenter.ChannelCategory4Desc6') }}</h3>
    <h3>5. {{ $t('HelpCenter.ChannelCategory4Desc7') }} {{ $t('HelpCenter.ChannelCategory4Desc8') }}</h3><br/>
    <h3> {{ $t('HelpCenter.ChannelCategory4Desc9') }}</h3>
    <h3>1. {{ $t('HelpCenter.ChannelCategory4Desc10') }}</h3>
    <h3>2. {{ $t('HelpCenter.ChannelCategory4Desc11') }}</h3>
    <h3>3. {{ $t('HelpCenter.ChannelCategory4Desc12') }}</h3>
    <h3>4. {{ $t('HelpCenter.ChannelCategory4Desc13') }}</h3>
    <h3>5. {{ $t('HelpCenter.ChannelCategory4Desc14') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory4Desc15') }} {{ $t('HelpCenter.ChannelCategory4Desc16') }}</h3>
  </div>
</template>
<script>
export default {
  name: 'AddSubscribersInChannel'
}
</script>
<style lang="less" scoped>
  .handle-content {
    margin-bottom: 20px;
    h1 {
      font-size: 30px;
      font-weight: 500;
      @media screen and (max-width: 992px) {
        margin-right: 35px;
        font-size: 24px;
      }
    }
    h3 {
      font-size: 18px;
      padding: 10px 0;
    }
    img {
      margin: 15px 0;
      width: 100%;
    }
  }
</style>
