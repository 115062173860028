<template>
  <div class="handle-content">
    <h1>{{ $t(`HelpCenter['category17']`) }}</h1>
    <h3>{{ $t(`FAQ['desc37']`) }}</h3>
    <p>1.{{ $t(`FAQ['desc38']`) }}</p>
    <p>2.{{ $t(`FAQ['desc39']`) }}</p>
    <p>3.{{ $t(`FAQ['desc40']`) }}</p>
    <p>4.{{ $t(`FAQ['desc41']`) }}</p>
  </div>
</template>
<script>
export default {
  name: 'HowToMakeVoiceCallonMosApp'
}
</script>
<style lang="less" scoped>
  .handle-content {
    margin-bottom: 20px;
    h1 {
      font-size: 30px;
      font-weight: 500;
      @media screen and (max-width: 992px) {
        margin-right: 35px;
        font-size: 24px;
      }
    }
    h3 {
      font-size: 18px;
      padding: 10px 0;
    }
    img {
      margin: 15px 0;
      width: 100%;
    }
  }
</style>
