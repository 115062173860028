import { render, staticRenderFns } from "./CreateAccountMosapp.vue?vue&type=template&id=b2503b8c&scoped=true&"
import script from "./CreateAccountMosapp.vue?vue&type=script&lang=js&"
export * from "./CreateAccountMosapp.vue?vue&type=script&lang=js&"
import style0 from "./CreateAccountMosapp.vue?vue&type=style&index=0&id=b2503b8c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2503b8c",
  null
  
)

export default component.exports