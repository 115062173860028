<template>
  <div class="handle-content">
    <h1>{{ $t(`HelpCenter['category23']`) }}</h1>
    <h3>{{ $t(`FAQ['desc55']`) }}</h3>
    <p>1.{{ $t(`FAQ['desc56']`) }}</p>
    <p>2.{{ $t(`FAQ['desc57']`) }}</p>
    <p>3.{{ $t(`FAQ['desc58']`) }}</p>
    <br>
    <p>{{ $t(`FAQ['desc59']`) }}</p>
    <p>{{ $t(`FAQ['desc69']`) }}</p>
    <br>
    <p>{{ $t(`FAQ['desc61']`) }}</p>
    <br>
    <p>{{ $t(`FAQ['desc62']`) }}</p>
    <p>{{ $t(`FAQ['desc63']`) }}</p>
    <br>
    <p>{{ $t(`FAQ['desc64']`) }}</p>
    <br>
    <p>1.{{ $t(`FAQ['desc65']`) }}</p>
    <p>2.{{ $t(`FAQ['desc66']`) }}</p>
  </div>
</template>
<script>
export default {
  name: 'MakeVideoCall'
}
</script>
<style lang="less" scoped>
  .handle-content {
    margin-bottom: 20px;
    h1 {
      font-size: 30px;
      font-weight: 500;
    }
    h3 {
      font-size: 18px;
      padding: 10px 0;
    }
    img {
      margin: 15px 0;
      width: 100%;
    }
  }
</style>
