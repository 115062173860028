<template>
  <div class="handle-content">
    {{ locale }}
    <h1>{{ $t('HelpCenter.category10') }}</h1>
    <h3>{{ $t('HelpCenter.category10Desc1') }}</h3>
    <h3>{{ $t('HelpCenter.category10Desc2') }}</h3>
    <div v-for="(image, index) in imgList" :key="index">
        <img :src="image[$i18n.locale + 'Img']" class="img-fluid" :key="index" />
        <h3>{{ $t(`HelpCenter.category10Desc${image.imgId}`) }}</h3>
    </div>
    <h3>{{ $t('HelpCenter.category10Desc6') }}</h3>
  </div>
</template>
<script>
export default {
  name: 'HowToTranslateaChatMessage',
  data() {
    return {
      imgList: [
        {
          zhImg: require('../../../assets/image/getStarts/zh/translateAchatMessage1.png'),
          enImg: require('../../../assets/image/getStarts/en/translateAchatMessage1.png'),
          imgId: 3
        },
        {
          zhImg: require('../../../assets/image/getStarts/zh/translateAchatMessage2.png'),
          enImg: require('../../../assets/image/getStarts/en/translateAchatMessage2.png'),
          imgId: 4
        },
        {
          zhImg: require('../../../assets/image/getStarts/zh/translateAchatMessage3.png'),
          enImg: require('../../../assets/image/getStarts/en/translateAchatMessage3.png'),
          imgId: 5
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.handle-content {
  margin-bottom: 20px;
  h1 {
    font-size: 34px;
    font-weight: 500;
    @media screen and (max-width: 992px) {
      margin-right: 35px;
      font-size: 24px;
    }
  }
  h3 {
    font-size: 20px;
    padding: 10px 0;
  }
  img {
    margin: 15px 0;
    width: 100%;
  }
}
</style>
