<template>
  <div class="handle-content">
    <h1>{{ $t(`HelpCenter['category24']`) }}</h1>
    <h3>{{ $t(`FAQ['desc43']`) }}</h3>
    <br>
    <p>{{ $t(`FAQ['desc44']`) }}</p>
    <br>
    <p>{{ $t(`FAQ['desc45']`) }}</p>
    <br>
    <p>{{ $t(`FAQ['desc46']`) }}</p>
    <p>{{ $t(`FAQ['desc47']`) }}</p>
    <p>{{ $t(`FAQ['desc48']`) }}</p>
    <br>
    <p>{{ $t(`FAQ['desc49']`) }}</p>
    <br>
    <p>1.{{ $t(`FAQ['desc50']`) }}</p>
    <p>2.{{ $t(`FAQ['desc51']`) }}</p>
    <p>3.{{ $t(`FAQ['desc52']`) }}</p>
    <p>4.{{ $t(`FAQ['desc53']`) }}</p>
    <p>5.{{ $t(`FAQ['desc54']`) }}</p>
  </div>
</template>
<script>
export default {
  name: 'MakeGroupVideoCall'
}
</script>
<style lang="less" scoped>
  .handle-content {
    margin-bottom: 20px;
    h1 {
      font-size: 30px;
      font-weight: 500;
    }
    h3 {
      font-size: 18px;
      padding: 10px 0;
    }
    img {
      margin: 15px 0;
      width: 100%;
    }
  }
</style>
