<template>
  <div class="handle-content">
    <h1>{{ $t('HelpCenter.ChannelCategory1') }}</h1>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc0') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc1') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc2') }}</h3>
    <h3>1. {{ $t('HelpCenter.ChannelCategory1Desc3') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc4') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc5') }}</h3>
    <h3>2. {{ $t('HelpCenter.ChannelCategory1Desc6') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc7') }}</h3>
    <h3>3. {{ $t('HelpCenter.ChannelCategory1Desc8') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc9') }}</h3>
    <h3>4. {{ $t('HelpCenter.ChannelCategory1Desc10') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc11') }}</h3>
    <h3>5. {{ $t('HelpCenter.ChannelCategory1Desc12') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc13') }}</h3>
    <h3>6. {{ $t('HelpCenter.ChannelCategory1Desc14') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc15') }}</h3>
    <h3>7. {{ $t('HelpCenter.ChannelCategory1Desc16') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc17') }}</h3>
    <h3>8. {{ $t('HelpCenter.ChannelCategory1Desc18') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory1Desc19') }}</h3>
  </div>
</template>
<script>
export default {
  name: 'ChannelFunctionMosApp'
}
</script>
<style lang="less" scoped>
  .handle-content {
    margin-bottom: 20px;
    h1 {
      font-size: 30px;
      font-weight: 500;
      @media screen and (max-width: 992px) {
        margin-right: 35px;
        font-size: 24px;
      }
    }
    h3 {
      font-size: 18px;
      padding: 10px 0;
    }
    img {
      margin: 15px 0;
      width: 100%;
    }
  }
</style>
