<template>
  <div class="handle-content">
    <h1>{{ $t('HelpCenter.ChannelCategory3') }}</h1>
    <h3>1. {{ $t('HelpCenter.ChannelCategory3Desc1') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc2') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc3') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc4') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc5') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc6') }}</h3>
    <h3>2. {{ $t('HelpCenter.ChannelCategory3Desc7') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc8') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc9') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc10') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc11') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc12') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc13') }}</h3>
    <h3>- {{ $t('HelpCenter.ChannelCategory3Desc14') }}</h3>
  </div>
</template>
<script>
export default {
  name: 'WhatIsDifferenceBetweenPublicAndPrivateChannel'
}
</script>
<style lang="less" scoped>
  .handle-content {
    margin-bottom: 20px;
    h1 {
      font-size: 30px;
      font-weight: 500;
      @media screen and (max-width: 992px) {
        margin-right: 35px;
        font-size: 24px;
      }
    }
    h3 {
      font-size: 18px;
      padding: 10px 0;
    }
    img {
      margin: 15px 0;
      width: 100%;
    }
  }
</style>
