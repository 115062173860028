<template>
  <div class="handle-content">
    <h1>{{ $t('HelpCenter.ChannelCategory2') }}</h1>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc1') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc2') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc3') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc4') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc5') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc6') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc7') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc8') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc9') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc10') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc11') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc12') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc13') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc14') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc15') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc16') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc17') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc18') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc19') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc20') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc21') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc22') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc23') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc24') }}</h3>
    <h3>{{ $t('HelpCenter.ChannelCategory2Desc25') }}</h3>
  </div>
</template>
<script>
export default {
  name: 'CreatePrivateChannelMosApp'
}
</script>
<style lang="less" scoped>
  .handle-content {
    margin-bottom: 20px;
    h1 {
      font-size: 30px;
      font-weight: 500;
      @media screen and (max-width: 992px) {
        margin-right: 35px;
        font-size: 24px;
      }
    }
    h3 {
      font-size: 18px;
      padding: 10px 0;
    }
    img {
      margin: 15px 0;
      width: 100%;
    }
  }
</style>
